@import '../../../styles/propertySets.css';

.root {
}

.title {
  @apply --marketplaceH1FontStyles;
  margin-top: 0;
}

.columns {
  @media (--viewportMedium) {
    display: flex;
    margin: 0 -15px;
  }
}

.box {
  background-color: var(--marketplaceColorBeige);
  padding: 30px 40px;
  margin: 0 0 20px;

  @media (--viewportMedium) {
    flex: 0 0 calc(50% - 30px);
    margin: 0 15px 20px;
  }

  &:first-child {
    background-image: url('data:image/svg+xml;utf8,<svg width="100%" height="100%" viewBox="0 0 122 131" version="1.1" xmlns="http://www.w3.org/2000/svg" ><path d="M0,130.381c0,0 23.934,-24.96 28.038,-35.218c4.102,-10.258 7.18,-33.85 20.515,-34.876c13.335,-1.026 28.038,-1.368 35.56,-18.122c7.522,-16.754 19.831,-44.108 29.063,-42.056c9.232,2.051 8.206,100.867 8.206,100.867c-0,0 -35.218,1.367 -51.288,12.993c-16.071,11.625 -52.607,16.412 -52.607,16.412l-17.487,0Z" fill="%23ae5a00" fill-opacity="0.3"/></svg>');
    background-position-x: calc(100% + 140px);
    background-position-y: calc(100% + 40px);
  }

  &:last-child {
    background-image: url('data:image/svg+xml;utf8,<svg width="100%" height="100%" viewBox="0 0 151 103" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M5.763,56.87c0,-0 -16.412,-48.553 6.155,-55.05c22.567,-6.496 11.967,5.813 22.908,14.703c10.942,8.89 9.781,-1.373 17.438,3.761c30.09,20.174 5.869,11.71 25.987,26.328c43.364,31.511 72.146,37.954 72.146,37.954c0,-0 -11.626,4.787 -24.619,-1.368c-9.449,-4.476 -6.155,8.89 -21.199,4.445c-9.826,-2.903 -6.497,1.368 -3.078,4.103c3.42,2.735 6.155,7.522 -4.786,5.129c-10.942,-2.394 -20.515,-12.993 -28.722,-8.206c-8.206,4.787 3.078,22.157 -22.909,8.856c-25.986,-13.301 -35.56,-9.882 -37.953,-22.875c-2.394,-12.993 -1.368,-17.78 -1.368,-17.78" fill="%2307454c" fill-opacity="0.3"/></svg>');
    background-position-x: calc(100% + 260px);
    background-position-y: calc(100% - 260px);
  }

  & li {
    line-height: 1.4;
    margin-bottom: 10px;
    padding-left: 20px;
    position: relative;

    &::before {
      content: '‣';
      color: var(--marketplaceColor);
      font-size: 40px;
      line-height: 22px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.boxTitle {
  margin-top: 0;
}

.boxButton {
  @apply --marketplaceButtonStyles;
  display: inline-block;
  margin-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  width: auto;
}

.faqLink {
  display: inline-block;
  margin-top: 18px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}
